import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useRouteLoaderData,
  type LinksFunction,
  type LoaderFunctionArgs,
} from "react-router";
import { Route } from "./+types/root";

import { authkitLoader } from "@workos-inc/authkit-remix";
import { useEffect } from "react";
import styles from "~/tailwind.css?url";
import { Impersonation } from "./components/impersonation";
import i18n from "./i18next.server";

import Hotjar from "@hotjar/browser";
import ErrorPage from "./components/errors/ErrorPage";

const siteId = 5345894;
const hotjarVersion = 6;

export const loader = async (args: LoaderFunctionArgs) => {
  return authkitLoader(args, async () => {
    const locale = await i18n.getLocale(args.request);
    const origin = new URL(args.request.url).origin;
    return { origin, locale };
  });
};

export const links: LinksFunction = () => [{ rel: "stylesheet", href: styles }];

export function Layout({ children }: { children: React.ReactNode }) {
  // Get the locale from the loader
  const data = useRouteLoaderData("root");

  const lang = data ? data.locale : "en";
  const { pathname } = useLocation();
  const canonicalUrl = data
    ? data.origin + (pathname.endsWith("/") ? pathname.slice(0, -1) : pathname)
    : undefined;

  const impersonator = data?.impersonator;

  useEffect(() => {
    Hotjar.init(siteId, hotjarVersion);
  }, []);

  return (
    // <html lang={lang} dir={i18n.dir(lang)}>
    <html lang={lang}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        <Meta />
        <Links />
      </head>
      <body>
        <Impersonation impersonator={impersonator} user={data?.user} />
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export function ErrorBoundary({ error }: Route.ErrorBoundaryProps) {
  return <ErrorPage error={error} />;
}

function App() {
  return (
    <div>
      <Outlet />
    </div>
  );
}

export default App;
