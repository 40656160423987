import { AlertCircleIcon, LockIcon, ServerCrashIcon } from "lucide-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { isRouteErrorResponse, Link } from "react-router";

interface ErrorPageProps {
  error: unknown;
}
const ErrorPage: React.FC<ErrorPageProps> = ({ error }) => {
  const { t } = useTranslation();

  const errorTitles: Record<number, string> = {
    404: t("error.notFoundTitle"),
    401: t("error.notAuthorizedTitle"),
    403: t("error.forbiddenTitle"),
    422: t("error.unprocessableEntityTitle"),
    500: t("error.internalServerErrorTitle"),
    502: t("error.badGatewayTitle"),
    503: t("error.serviceUnavailableTitle"),
    504: t("error.gatewayTimeoutTitle"),
  };

  const errorMessages: Record<number, string> = {
    404: t("error.notFound"),
    401: t("error.notAuthorized"),
    403: t("error.forbidden"),
    422: t("error.unprocessableEntity"),
    500: t("error.internalServerError"),
    502: t("error.badGateway"),
    503: t("error.serviceUnavailable"),
    504: t("error.gatewayTimeout"),
  };

  const errorIcons: Record<number, React.ReactNode> = {
    404: <AlertCircleIcon className="h-12 w-12 text-primary" />,
    401: <LockIcon className="h-12 w-12 text-primary" />,
    403: <LockIcon className="h-12 w-12 text-primary" />,
    422: <AlertCircleIcon className="h-12 w-12 text-primary" />,
    500: <ServerCrashIcon className="h-12 w-12 text-primary" />,
    502: <ServerCrashIcon className="h-12 w-12 text-primary" />,
    503: <ServerCrashIcon className="h-12 w-12 text-primary" />,
    504: <ServerCrashIcon className="h-12 w-12 text-primary" />,
  };

  let status = 500; // Default to 500 for internal server error
  console.log("Error type:", typeof error);
  console.log("Error:", error);
  if (isRouteErrorResponse(error)) {
    status = error.status;
  }

  const errorMessage = errorMessages[status] || t("error.defaultError");
  const errorTitle = errorTitles[status] || t("error.defaultErrorTitle");
  const errorIcon = errorIcons[status] || (
    <ServerCrashIcon className="h-12 w-12 text-primary" />
  );

  return (
    <>
      <div className="flex min-h-[100dvh] flex-col items-center justify-center bg-background px-4 py-12 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-lg text-center">
          <h1 className="mt-4 text-4xl font-bold tracking-tight text-foreground sm:text-5xl">
            <div className="flex flex-col items-center">
              {errorIcon}
              <span className="pt-4">{errorTitle}</span>
            </div>
          </h1>
          <p className="mt-4 text-lg text-muted-foreground">{errorMessage}</p>

          <div className="mt-6">
            <Link
              to="/"
              className="inline-flex items-center rounded-md bg-primary px-4 py-2 text-sm font-medium text-primary-foreground shadow-sm transition-colors hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            >
              {t("error.goToHomepage")}
            </Link>
          </div>
        </div>
      </div>

      {error?.stack && (
        <div className="p-8">
          <p>The stack trace is:</p>
          <pre>{error.stack}</pre>
        </div>
      )}
    </>
  );
};

export default ErrorPage;
